// UserInfoInput.js
import React from 'react';
import { TextField, Button } from '@mui/material';

const UserInfoInput = ({ uid, setUid, handleGetUserInfo }) => (
    <div className="flex flex-col items-center my-16">
        <TextField
            label="UID/Email"
            variant="outlined"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            sx={{ mb: '10px', width: '40%' }}
        />
        <Button variant="contained" color="primary" sx={{ mb: '10px', width: '40%' }} onClick={handleGetUserInfo}>
            Get User Info
        </Button>
    </div>
);

export default UserInfoInput;
