/* eslint-disable array-callback-return */
import secureLocalStorage from 'react-secure-storage'
import React, { useEffect, useState } from 'react'
import './ViewEachTicket.css'
import { Autocomplete, Button, Snackbar, Stack, TextField } from '@mui/material'

import imageIcon from '../../../assets/images/imageIcon.png'
import personIcon from '../../../assets/images/personIcon2.png'
import personIcon2 from '../../../assets/images/customer2.png'
import noteIcon from '../../../assets/images/note_icon.png'
import sendButton from '../../../assets/images/sendButton.png'
import clockIcon from '../../../assets/images/clock1.png'
import historyIcon from '../../../assets/images/history.png'
import { useLocation, useNavigate } from 'react-router-dom'
import MuiAlert from '@mui/material/Alert'
import notFoundImage from '../../../assets/images/notfound3.png'
import { formatDateToYYYYMMDDHHMM, formatMillisecondsDate, showTimeInAgoFormat } from '../../../Utils/time'
import { cannedResponseAutocomplete, getReplyFromCannedResponseId } from '../../../arrays/cannedResponses'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ClockIcon from '../../../assets/images/clock.png'
import WarningIcon from '../../../assets/images/warning.png'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const defaultReply = `Dear user, \n\nThanks for reaching out to us. We apologize for the inconvenience.\n\n\n\nThank you.`

function ViewEachTicket() {
    const location = useLocation()
    const navigate = useNavigate()

    const [ticketId, setTicketId] = useState('')
    const [ticketDoc, setTicketDoc] = useState({ description: '', sourceData: { app: '', email: '', platform: '', uid: '' }, files: [], time: new Date().getTime(), updatedAt: new Date().getTime(), userInfo: { email: '' }, assigned: 'Support', privateNote: '', status: 'pending', developer: 'None' })
    const [chatHistory, setChatHistory] = useState([])
    const [currentStatus, setcurrentStatus] = useState('pending')
    const [currentFilter, setCurrentFilter] = useState('Pending')
    const [currentDeveloper, setCurrentDeveloper] = useState('None')
    const [currentFilterId, setCurrentFilterId] = useState('pending')
    const [supportReply, setSupportReply] = useState(defaultReply)
    const [currentCannedResponse, setCurrentCannedResponse] = useState('')
    const dashboardUid = secureLocalStorage.getItem('uid')
    const [ticketsHistoryList, setTicketsHistoryList] = useState([])
    const [privateNote, setPrivateNote] = useState('')
    const [invalidTicket, setInvalidTicket] = useState(false)
    const [showLoading, setShowLoading] = useState(true)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [fileUploadText, setFileUploadText] = useState('Choose Files')

    const [developerList, setDeveloperList] = useState([
        {
            label: 'None'
        }
    ])

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]
        setSelectedFiles([selectedFile])
        if (selectedFile) {
            setFileUploadText('File chosen - ' + selectedFile.name)
            if (selectedFile.type.startsWith('image/')) {
                console.log('Selected file is an image:', selectedFile)
            } else if (selectedFile.type.startsWith('video/')) {
                console.log('Selected file is a video:', selectedFile)
            } else {
                console.error('Unsupported file type:', selectedFile.type)
            }
            console.log('Selected File:: ', selectedFile)
        } else {
            setErrorMessage('Please select valid attachment')
            setOpenErrorAlert(true)
        }
    }

    const handleFileUpload = () => {
        if (selectedFiles.length === 0) {
            setErrorMessage('Please select files to upload')
            setOpenErrorAlert(true)
            return
        }

        const formData = new FormData()
        formData.append('sender', 'support')
        formData.append('ticketId', ticketId)
        formData.append('file', selectedFiles[0])

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        fetch(`https://dev.blockerx.net/api/tickets/v2/attachments`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('SuCCESS:::', data)
                setSuccessMessage(data.data.info)
                setOpenSuccessAlert(true)
                setSuccessMessage('Files have been uploaded')
                setOpenSuccessAlert(true)
                setSelectedFiles([])
                window.location.reload()
            })
            .catch((error) => {
                console.log('ERRRROR ::: ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
        return
    }

    const handleFileUploadForDevelopers = () => {
        if (selectedFiles.length === 0) {
            setErrorMessage('Please select files to upload')
            setOpenErrorAlert(true)
            return
        }

        const formData = new FormData()
        formData.append('sender', 'internal')
        formData.append('ticketId', ticketId)
        formData.append('file', selectedFiles[0])
        formData.append('dashboardUid', dashboardUid)

        const requestOptions = {
            method: 'POST',
            body: formData
        }

        fetch(`https://dev.blockerx.net/api/tickets/v3/attachments`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setSuccessMessage(data.data.info)
                setOpenSuccessAlert(true)
                setSuccessMessage('Files have been uploaded')
                setOpenSuccessAlert(true)
                setSelectedFiles([])
                window.location.reload()
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
        return
    }

    const getChatHistoryOfTicket = (ID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ticketId: ID
            })
        }

        fetch(`https://dev.blockerx.net/api/tickets/chat/v2/history`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    const array = data.data.history
                    setChatHistory(array)
                    setTicketDoc(data.data.ticket)
                    getTicketHistoryOfUser(data.data.ticket.userInfo.email)
                    setShowLoading(false)
                    setCurrentFilter(getLabelById(data.data.ticket.status))
                    setCurrentDeveloper(data.data.ticket.developer || 'None')
                } else {
                    setInvalidTicket(true)
                    setShowLoading(false)
                }
            })
            .catch((error) => {
                setInvalidTicket(true)
                setShowLoading(false)
            })
    }

    const parseTextForLink = (text) => {
        const parts = text.split(/(https?:\/\/[^\s]+)/)

        const parsedText = parts.map((part, index) => {
            if (part.match(/https?:\/\/[^\s]+/)) {
                return (
                    <a key={index} href={part} style={{ textDecoration: 'underline', fontWeight: 'bold', color: 'blue' }} target='_blank' rel='noopener noreferrer'>
                        Link
                    </a>
                )
            } else {
                return <span key={index}>{part}</span>
            }
        })

        return <div style={{ whiteSpace: 'pre-line' }}>{parsedText}</div>
    }

    const addDataToChatHistory = (message) => {
        let newChatArray = chatHistory
        newChatArray.push({
            _id: new Date().getTime(),
            sender: 'support',
            message: message,
            time: new Date().getTime()
        })
        setChatHistory(newChatArray)
    }

    const addPrivateNoteToChatHistory = (note) => {
        let newChatArray = chatHistory
        newChatArray.push({
            _id: new Date().getTime(),
            sender: 'internal',
            name: secureLocalStorage.getItem('fullname'),
            message: note,
            time: new Date().getTime()
        })
        setChatHistory(newChatArray)
    }

    const sendSupportReplyHandler = () => {
        addDataToChatHistory(supportReply)
        setSupportReply('')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dashboardUid: dashboardUid,
                ticketId: ticketId,
                sender: 'support',
                message: supportReply
            })
        }

        fetch(`https://dev.blockerx.net/api/tickets/chat`, requestOptions)
            .then((response) => response.json())
            .then((data) => { })
            .catch((error) => { })
    }

    const handleHistoryOfUserTicket = (allHistoricalTickets) => {
        setTicketsHistoryList(allHistoricalTickets)
    }

    const getTicketHistoryOfUser = (email) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email
            })
        }

        fetch(`https://dev.blockerx.net/api/dashboard/tickets/history`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    const allTickets = data.data.list
                    handleHistoryOfUserTicket(allTickets)
                } else {
                }
            })
            .catch((error) => { })
    }

    const updateTicketStatus = () => {
        setSuccessMessage('Ticket Status has been updated')
        setOpenSuccessAlert(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dashboardUid: dashboardUid,
                ticketId: ticketId,
                status: currentStatus
            })
        }

        fetch(`https://dev.blockerx.net/api/tickets/status`, requestOptions)
            .then((response) => response.json())
            .then((data) => { })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const updatePrivateNoteOfTicket = () => {
        setSuccessMessage('Private note has been updated')
        addPrivateNoteToChatHistory(privateNote)
        setOpenSuccessAlert(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dashboardUid: dashboardUid,
                ticketId: ticketId,
                note: privateNote
            })
        }

        fetch(`https://dev.blockerx.net/api/dashboard/tickets/privateNote`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPrivateNote('')
            })
            .catch((error) => { })
    }

    const updateDeveloperOfTicket = () => {
        setSuccessMessage(`Ticket assigned to ${currentDeveloper}`)
        setOpenSuccessAlert(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dashboardUid: dashboardUid,
                ticketId: ticketId,
                developer: currentDeveloper
            })
        }

        fetch(`https://dev.blockerx.net/api/tickets/developer`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setSuccessMessage(data.data.info)
            })
            .catch((error) => { })
    }

    const openFileInNewFolder = (url) => {
        window.open(url, '_blank')
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const showTicketStatus = (text) => {
        const words = text.split('_')
        let result = ''
        words.forEach((word) => {
            result += capitalizeFirstLetter(word) + ' '
        })
        return result
    }

    const setSupportReplyFromCannedResponse = (text) => {
        const reply = getReplyFromCannedResponseId(text)
        console.log('Reply  : ', reply)
        setSupportReply(reply)
    }

    // Success and Error alerts

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const openUserTickerHistory = () => {
        navigate(`/customer-tickets/users/${ticketDoc.userInfo.email}`)
    }

    const openTicketInNewTab = (ticketID) => {
        const url = `/customer-tickets/${ticketID}`
        window.open(url, '_self')
    }

    const handleGoBack = () => {
        navigate('/customer-tickets')
    }

    const openImageInNewTab = (url) => {
        window.open(url, '_blank')
    }

    const navigateToUserInfoPage = (email, app) => {
        try {
            if (app === 'zenze') {
                window.open(`/zenze-userinfo?user=${email}`, '_blank')
            } else {
                window.open(`/userinfo/${email}`, '_blank')
            }
        } catch {
            window.open(`/userinfo/${email}`, '_blank')
        }
    }

    const copyUserMailHandler = () => {
        navigator.clipboard.writeText(ticketDoc.userInfo.email)
        setSuccessMessage(`${ticketDoc.userInfo.email} has been copied to clipboard!`)
        setOpenSuccessAlert(true)
    }

    const getPeersList = () => {
        try {
            const data = secureLocalStorage.getItem('dashboard_peers')
            const developers_list = [{ label: 'None' }]
            data.forEach((each_data) => {
                developers_list.push({ label: each_data.fullname })
            })
            setDeveloperList(developers_list)
        } catch (err) {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
            fetch('https://dev.blockerx.net/api/dashboard/peers', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        secureLocalStorage.setItem('dashboard_peers', data.data.users)
                        const developers_list = [{ label: 'None' }]
                        data.data.users.forEach((each_data) => {
                            developers_list.push({ label: each_data.fullname })
                        })
                        setDeveloperList(developers_list)
                    } else {
                    }
                })
                .catch()
        }
    }

    useEffect(() => {
        const path = location.pathname
        const ticket_id = path.substring(18)
        document.title = `Ticket - ${ticket_id}`
        getChatHistoryOfTicket(ticket_id)
        setTicketId(ticket_id)
        getPeersList()
        window.scrollTo(0, 0)
    }, [])

    const textWithEntities = (text) => {
        const decodedText = new DOMParser().parseFromString(text, 'text/html').body.textContent
        return <div dangerouslySetInnerHTML={{ __html: decodedText }} />
    }

    const goToEndOfPage = () => {
        window.scrollTo({ top: document.body.scrollHeight * 0.85, behavior: 'smooth' })
    }

    const isURL = (str) => {
        try {
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
            return urlRegex.test(str.trim())
        } catch {
            return false
        }
    }

    function isVideo(message) {
        return message.endsWith('.mp4') || message.endsWith('.avi')
    }

    function isImage(message) {
        const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
        const lowerCaseMessage = message.toLowerCase()

        return imageExtensions.some((extension) => lowerCaseMessage.endsWith(extension))
    }

    const getAppName = (app) => {
        try {
            return capitalizeFirstLetter(app.sourceData.app)
        } catch {
            return 'BlockerX'
        }
    }

    return (
        <>
            {!invalidTicket && !showLoading && (
                <div className='main__ticket__container'>
                    <div className='ticket__view__container'>
                        <div className='ticket__heading font-bold'>
                            <span>{'Conversation with'}</span>
                            <span className='text-white'>{' - '}</span>
                            <span className='cursor-pointer hover:text-blue-500' onClick={() => navigateToUserInfoPage(ticketDoc.userInfo.email, ticketDoc?.sourceData?.app || '')}>
                                {'' + ticketDoc.userInfo.email}
                            </span>
                            <ContentCopyIcon className='cursor-pointer text-blue-800 mx-4' onClick={copyUserMailHandler} />
                            <img className='history__icon cursor-pointer' src={historyIcon} onClick={openUserTickerHistory} alt='history' />
                        </div>
                        <div className='time__container'>
                            <img className='cursor-pointer' onClick={goToEndOfPage} src={clockIcon} alt='' />
                            Created: {showTimeInAgoFormat(ticketDoc.time)} &bull; {formatMillisecondsDate(ticketDoc.time)}
                        </div>
                        <div className='time__container text-xl font-bold'>📱App : {getAppName(ticketDoc)} {' '}🎯 {' '}{'Status : ' + getLabelById(ticketDoc.status)}</div>
                        <div className='time__container_2 text-blue-700'></div>
                        {ticketDoc.description.length > 0 && (
                            <div className='ticket__description'>
                                <div className='text__description__text'>{textWithEntities(ticketDoc.description)}</div>
                            </div>
                        )}
                        {ticketDoc.files.length > 0 && (
                            <>
                                <div className='normal__heading'>Attached Files</div>
                                <div className='ticket__assets'>
                                    {ticketDoc.files.map((file) => {
                                        return <img src={imageIcon} alt='' onClick={() => openFileInNewFolder(file)} />
                                    })}
                                </div>
                            </>
                        )}
                        <div className='chat__container'>
                            {chatHistory.length > 0 &&
                                chatHistory.map((each_chat) => {
                                    if (each_chat.sender === 'support') {
                                        if (isURL(each_chat.message)) {
                                            return (
                                                <div className='ticket__description__image'>
                                                    <div className='text__description__text__image__support'>
                                                        {isVideo(each_chat.message) ? (
                                                            <video controls className='cursor-pointer' onClick={() => openImageInNewTab(each_chat.message)}>
                                                                <source src={each_chat.message} type='video/mp4' />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : isImage(each_chat.message) ? (
                                                            <img src={each_chat.message} alt='' className='cursor-pointer' onClick={() => openImageInNewTab(each_chat.message)} />
                                                        ) : (
                                                            <p>{parseTextForLink(each_chat.message)}</p>
                                                        )}
                                                        <div className='conversation__time'>
                                                            {each_chat.seen && <>Seen &bull;</>}{showTimeInAgoFormat(each_chat.time)} &bull; {formatDateToYYYYMMDDHHMM(each_chat.time)} &bull;  {capitalizeFirstLetter(ticketDoc.assigned)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='ticket__description__support'>
                                                    <div className='text__description__text__support'>
                                                        <img src={personIcon2} alt='' />
                                                        {parseTextForLink(each_chat.message)}
                                                        <div className='conversation__time'>
                                                            {each_chat.seen && <>Seen &bull;</>} {showTimeInAgoFormat(each_chat.time)} &bull; {formatDateToYYYYMMDDHHMM(each_chat.time)} &bull;  {capitalizeFirstLetter(ticketDoc.assigned)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else if (each_chat.sender === 'user') {
                                        if (isURL(each_chat.message)) {
                                            return (
                                                <div className='ticket__description__image'>
                                                    <div className='text__description__text__image__user'>
                                                        {isVideo(each_chat.message) ? (
                                                            <video controls className='cursor-pointer' onClick={() => openImageInNewTab(each_chat.message)}>
                                                                <source src={each_chat.message} type='video/mp4' />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : isImage(each_chat.message) ? (
                                                            <img src={each_chat.message} alt='' className='cursor-pointer' onClick={() => openImageInNewTab(each_chat.message)} />
                                                        ) : (
                                                            <p>{parseTextForLink(each_chat.message)}</p>
                                                        )}
                                                        <div className='conversation__time'>{showTimeInAgoFormat(each_chat.time)} &bull;{formatDateToYYYYMMDDHHMM(each_chat.time)}</div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='ticket__description'>
                                                    <div className='text__description__text'>
                                                        <img src={personIcon} alt='' />
                                                        {parseTextForLink(each_chat.message)}
                                                        <div className='conversation__time'>{showTimeInAgoFormat(each_chat.time)} &bull;{formatDateToYYYYMMDDHHMM(each_chat.time)}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else if (each_chat.sender === 'internal') {
                                        if (isURL(each_chat.message)) {
                                            return (
                                                <div className='ticket__description__image'>
                                                    <div className='text__description__text__image__support border-black private__note__image__container'>
                                                        {isVideo(each_chat.message) ? (
                                                            <video controls className='cursor-pointer' onClick={() => openImageInNewTab(each_chat.message)}>
                                                                <source src={each_chat.message} type='video/mp4' />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : isImage(each_chat.message) ? (
                                                            <img src={each_chat.message} alt='' className='rounded cursor-pointer private__note__image' onClick={() => openImageInNewTab(each_chat.message)} />
                                                        ) : (
                                                            <p>{parseTextForLink(each_chat.message)}</p>
                                                        )}
                                                        <div className='conversation__time'>{showTimeInAgoFormat(each_chat.time)} &bull; {capitalizeFirstLetter(each_chat.name)} &bull;{formatDateToYYYYMMDDHHMM(each_chat.time)} </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='ticket__description__internal'>
                                                    <div className='text__description__text__internal'>
                                                        <img src={noteIcon} alt='' />
                                                        {parseTextForLink(each_chat.message)}
                                                        <div className='conversation__time'>
                                                            {showTimeInAgoFormat(each_chat.time)} &bull; {capitalizeFirstLetter(each_chat.name)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                })}
                        </div>
                        <div className='reply__container'>
                            <Stack spacing={2} sx={{ width: '100%', marginTop: '10px' }}>
                                <Autocomplete
                                    id=''
                                    freeSolo
                                    value={currentCannedResponse}
                                    options={cannedResponseAutocomplete.map((option) => option.label)}
                                    onChange={(event, item) => {
                                        if (item === undefined || item === null) return
                                        setCurrentCannedResponse(item.text)
                                        setSupportReplyFromCannedResponse(item)
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Canned Responses' />}
                                />
                            </Stack>
                        </div>
                        <div className='reply__container'>
                            <TextField
                                type='text'
                                multiline
                                label='Reply to conversation'
                                disablePortal
                                value={supportReply}
                                showlines={4}
                                id='combo-box-demo'
                                sx={{ width: '100%', marginTop: '5px' }}
                                onChange={(event) => {
                                    setSupportReply(event.target.value)
                                }}
                            />
                            <div>
                                <img src={sendButton} onClick={sendSupportReplyHandler} alt='' />
                            </div>
                        </div>
                        <div className='normal__heading'>Files and Status</div>
                        <div className='ticket__assignedTo my-2'>
                            <label className='custom-file-upload text-black py-2 px-4 cursor-pointer w-4/5 border border-gray-300 h-14 flex items-center rounded-md font-sans'>
                                <input type='file' onChange={handleFileChange} accept='image/*, video/*' className='hidden' />
                                {fileUploadText}
                            </label>
                            <Button className='h-12' sx={{ width: '20%', backgroundColor: '#2c3331' }} onClick={handleFileUpload} variant='contained'>
                                Upload
                            </Button>
                        </div>
                        <div className='ticket__assignedTo'>
                            <Autocomplete
                                disablePortalnpm
                                s
                                id='combo-box-demo'
                                value={currentFilter}
                                options={filterList}
                                sx={{ width: '80%' }}
                                onChange={(event, item) => {
                                    setCurrentFilter(item.label)
                                    setCurrentFilterId(item.id)
                                    setcurrentStatus(item.id)
                                }}
                                renderInput={(params) => <TextField {...params} label='Ticket Status' />}
                            />
                            <Button className='h-12' sx={{ width: '20%' }} onClick={updateTicketStatus} variant='contained'>
                                Update
                            </Button>
                        </div>
                        <div className='normal__heading'>Developers Options</div>
                        <div className='ticket__assignedTo'>
                            <TextField
                                type='text'
                                multiline
                                label='Private Note'
                                disablePortal
                                value={privateNote}
                                showlines={4}
                                id='combo-box-demo'
                                sx={{ width: '80%', marginTop: '5px' }}
                                onChange={(event) => {
                                    setPrivateNote(event.target.value)
                                }}
                            />
                            <Button className='h-12' sx={{ width: '20%' }} color='success' onClick={updatePrivateNoteOfTicket} variant='contained'>
                                Add
                            </Button>
                        </div>
                        <div className='ticket__assignedTo my-2'>
                            <label className='custom-file-upload text-black py-2 px-4 cursor-pointer w-4/5 border border-gray-300 h-14 flex items-center rounded-md font-sans'>
                                <input type='file' onChange={handleFileChange} accept='image/*, video/*' className='hidden' />
                                {fileUploadText}
                            </label>
                            <Button className='h-12' sx={{ width: '20%', backgroundColor: '#2c3331' }} onClick={handleFileUploadForDevelopers} variant='contained'>
                                Upload
                            </Button>
                        </div>
                        <div className='ticket__assignedTo mt-2'>
                            <Autocomplete
                                disablePortalnpm
                                s
                                id='combo-box-demo'
                                value={currentDeveloper}
                                options={developerList}
                                sx={{ width: '80%' }}
                                onChange={(event, item) => {
                                    setCurrentDeveloper(item.label)
                                }}
                                renderInput={(params) => <TextField {...params} label='Assign to Developer' />}
                            />
                            <Button className='h-12' sx={{ width: '20%', backgroundColor: '#2c3331' }} onClick={updateDeveloperOfTicket} variant='contained'>
                                Assign
                            </Button>
                        </div>
                    </div>
                    <div className='right__side__history'>
                        <div className='font-bold text-2xl'>Ticket History</div>
                        {ticketsHistoryList.length === 1 && (
                            <div className='small__icon'>
                                <img src={notFoundImage} alt='' />
                            </div>
                        )}
                        {ticketsHistoryList.length > 1 &&
                            ticketsHistoryList.map((eachHistoricalTicket) => {
                                if (eachHistoricalTicket._id === ticketId) {
                                    return (
                                        <div className='cursor-pointer each__historical__ticket current__ticket' key={eachHistoricalTicket._id} onClick={() => openTicketInNewTab(eachHistoricalTicket._id)}>
                                            <div>Ticket No : {eachHistoricalTicket._id}</div>
                                            <div>Created: {showTimeInAgoFormat(eachHistoricalTicket.time)}</div>
                                            <div>Description: {eachHistoricalTicket.description}</div>
                                            <div className='font-bold text-gray-700'>{showTicketStatus(eachHistoricalTicket.status)}</div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='cursor-pointer each__historical__ticket' key={eachHistoricalTicket._id + '2'} onClick={() => openTicketInNewTab(eachHistoricalTicket._id)}>
                                            <div>Ticket No : {eachHistoricalTicket._id}</div>
                                            <div>Created: {showTimeInAgoFormat(eachHistoricalTicket.time)}</div>
                                            <div>Description: {eachHistoricalTicket.description}</div>
                                            <div className='font-bold text-gray-700'>{showTicketStatus(eachHistoricalTicket.status)}</div>
                                        </div>
                                    )
                                }
                            })}
                    </div>
                </div>
            )}

            {invalidTicket && (
                <div className='flex items-center justify-center mt-72'>
                    <div className='animate-spin-fast text-center'>
                        <img src={WarningIcon} alt='Warning Icon' className='w-20 h-20 mx-auto' />
                        <p className='header-top my-8'>Ticket Not Found</p>
                        <Button className='w-48 h-12' variant='contained' color='primary' onClick={handleGoBack}>
                            Go to All Tickets
                        </Button>
                    </div>
                </div>
            )}

            {showLoading && (
                <div className='flex items-center justify-center mt-72'>
                    <div className='animate-spin-fast text-center'>
                        <img src={ClockIcon} alt='Warning Icon' className='w-20 h-20 mx-auto' />
                        <p className='header-top my-8'>Loading ...</p>
                    </div>
                </div>
            )}

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    )
}

const getLabelById = (id) => {
    const filterItem = filterList.find((item) => item.id === id)
    return filterItem ? filterItem.label : 'Label Not Found'
}

export default ViewEachTicket

const filterList = [
    {
        label: 'Pending',
        id: 'pending'
    },
    {
        label: 'Tech Review',
        id: 'tech_review'
    },
    {
        label: 'Tech Complete',
        id: 'tech_complete'
    },
    {
        label: 'Awaiting User Reply',
        id: 'awaiting_user_reply'
    },
    {
        label: 'User Follow Up',
        id: 'user_follow_up'
    },
    {
        label: 'Closed',
        id: 'closed'
    },
    {
        label: 'Silent Closed',
        id: 'silent_closed'
    }
]
