// ZenzeUserInfo.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ZENZE_BASE_URL } from '../../../api/Constants';
import UserInfoInput from './components/UserInput';
import TableSection from './components/TableSection';
import { AddCoinsDialog, EditUsernameDialog, ExtendTrialDialog } from './components/EditUsernameDialog';

const ZenzeUserInfo = () => {
    const [uid, setUid] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState('');
    const [editUsernameOpen, setEditUsernameOpen] = useState(false);
    const [addCoinsOpen, setAddCoinsOpen] = useState(false);
    const [extendTrialOpen, setExtendTrialOpen] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [coinsToAdd, setCoinsToAdd] = useState('');
    const [daysToExtend, setDaysToExtend] = useState('');

    // Get the query parameter 'user' from the URL
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user = queryParams.get('user'); // This could be email or UID

    // Initialize the UID if it exists in the URL query
    useEffect(() => {
        if (user) {
            setUid(user);
            handleGetUserInfo(user); // Fetch user info immediately when URL parameter is available
        }
    }, [user]);

    const handleGetUserInfo = async (userIdentifier) => {
        try {
            const response = await axios.get(`${ZENZE_BASE_URL}/api/dashboard/v1/user?id=${userIdentifier}`);
            if (response.data.status === 200) {
                setUserInfo(response.data.data.userInfo);
                setError('');
            } else {
                setError('Failed to fetch user info');
                setUserInfo(null);
            }
        } catch (error) {
            setError('Failed to fetch user info');
            setUserInfo(null);
        }
    };

    const handleUsernameUpdate = async () => {
        try {
            await axios.post(`${ZENZE_BASE_URL}/api/dashboard/v1/username`, {
                uid: userInfo.uid,
                oldUsername: userInfo.username,
                newUsername
            });
            setUserInfo({ ...userInfo, username: newUsername });
            setEditUsernameOpen(false);
        } catch (error) {
            console.error('Failed to update username', error);
        }
    };

    const handleAddCoins = async () => {
        try {
            await axios.post(`${ZENZE_BASE_URL}/api/dashboard/v1/coins`, {
                uid: userInfo.uid,
                coins: parseInt(coinsToAdd, 10)
            });
            setUserInfo({ ...userInfo, coins: userInfo.coins + parseInt(coinsToAdd, 10) });
            setAddCoinsOpen(false);
        } catch (error) {
            console.error('Failed to add coins', error);
        }
    };

    const handleExtendTrial = async () => {
        try {
            await axios.post(`${ZENZE_BASE_URL}/api/dashboard/v1/extend-freetrial`, {
                uid: userInfo.uid,
                extendDays: parseInt(daysToExtend, 10)
            });
            setExtendTrialOpen(false);
            handleGetUserInfo(userInfo.uid); // Refetch the updated info
        } catch (error) {
            console.error('Failed to extend free trial', error);
        }
    };

    const handleToggleSwitch = async (switchName, currentValue) => {
        try {
            const requestBody = {
                "uid": userInfo.uid,
                "switchName": switchName,
                "flag": !currentValue
            };

            await axios.post(`${ZENZE_BASE_URL}/api/v1/dashboard/force-switches`, requestBody, {
                headers: { 'Content-Type': 'application/json' }
            });

            setUserInfo({
                ...userInfo,
                forceSwitches: { ...userInfo.forceSwitches, [switchName]: !currentValue }
            });
        } catch (error) {
            console.error('Failed to toggle switch', error);
        }
    };

    return (
        <div>
            <UserInfoInput uid={uid} setUid={setUid} handleGetUserInfo={() => handleGetUserInfo(uid)} />
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {userInfo && (
                <>
                    <TableSection 
                        userInfo={userInfo} 
                        handleToggleSwitch={handleToggleSwitch} 
                        setEditUsernameOpen={setEditUsernameOpen}
                        setAddCoinsOpen={setAddCoinsOpen} 
                        setExtendTrialOpen={setExtendTrialOpen}
                    />
                    <EditUsernameDialog
                        open={editUsernameOpen} 
                        setOpen={setEditUsernameOpen} 
                        newUsername={newUsername} 
                        setNewUsername={setNewUsername} 
                        handleUsernameUpdate={handleUsernameUpdate} 
                    />
                    <AddCoinsDialog
                        open={addCoinsOpen} 
                        setOpen={setAddCoinsOpen} 
                        coinsToAdd={coinsToAdd} 
                        setCoinsToAdd={setCoinsToAdd} 
                        handleAddCoins={handleAddCoins} 
                    />
                    <ExtendTrialDialog
                        open={extendTrialOpen} 
                        setOpen={setExtendTrialOpen} 
                        daysToExtend={daysToExtend} 
                        setDaysToExtend={setDaysToExtend} 
                        handleExtendTrial={handleExtendTrial} 
                    />
                </>
            )}
        </div>
    );
};

export default ZenzeUserInfo;
