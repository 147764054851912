import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import { showTimeInAgoFormat } from '../../../Utils/time'

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const showTicketStatus = (text) => {
    const words = text.split('_')
    return words.map((word) => capitalizeFirstLetter(word)).join(' ')
}

function SearchTicketPage() {
    const [userEmail, setUserEmail] = useState('')
    const [ticketsList, setTicketsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const openTicketHandler = (ticketId) => {
        window.open(`/customer-tickets/${ticketId}`, '_blank')
    }

    const handleSearch = () => {
        if (!userEmail.trim()) {
            setError('Please enter a valid email address')
            return
        }
        setLoading(true)
        setError('')

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: userEmail })
        }

        fetch(`https://dev.blockerx.net/api/dashboard/tickets/history`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setTicketsList(data.data.list)
                } else {
                    setError('No tickets found for this email.')
                }
                setLoading(false)
            })
            .catch(() => {
                setError('Failed to fetch tickets. Please try again.')
                setLoading(false)
            })
    }

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold text-center mb-6">Search Tickets</h1>

            <div className="flex items-center gap-4 mb-6">
                <input
                    type="email"
                    className={`w-full p-3 border rounded-lg focus:outline-none ${
                        error ? 'border-red-500' : 'border-gray-300'
                    }`}
                    placeholder="Enter User Email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
                <button
                    onClick={handleSearch}
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition"
                >
                    Search
                </button>
            </div>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

            {loading && (
                <div className="flex justify-center my-6">
                    <CircularProgress />
                </div>
            )}

            <div className="grid grid-cols-1 gap-6">
                {ticketsList.length > 0 ? (
                    ticketsList.map((ticket) => (
                        <div
                            key={ticket._id}
                            onClick={() => {
                                openTicketHandler(ticket._id)
                            }}
                            className="bg-white shadow-lg p-4 rounded-lg hover:shadow-xl transition cursor-pointer"
                        >
                            <h2 className="text-xl font-semibold mb-2">
                                {capitalizeFirstLetter(ticket.tag)} issue in {capitalizeFirstLetter(ticket.appName)} on{' '}
                                {capitalizeFirstLetter(ticket.platform)}
                            </h2>
                            <p className="text-sm text-gray-500">
                                User Responded: {showTimeInAgoFormat(ticket.updatedAt)}
                            </p>
                            <p className="text-sm text-gray-500">
                                Customer Team: {showTimeInAgoFormat(ticket.respondedAt)}
                            </p>
                            <p className="mt-2">{ticket.description}</p>
                            <div className="flex justify-between items-center mt-4">
                                <span className="text-sm font-bold text-red-600">
                                    Status: {showTicketStatus(ticket.status)}
                                </span>
                                <span className="text-sm text-gray-500">
                                    Ticket Created: {showTimeInAgoFormat(ticket.time)}
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    !loading && (
                        <p className="text-center text-gray-500 text-lg mt-6">
                            No tickets found.
                        </p>
                    )
                )}
            </div>
        </div>
    )
}

export default SearchTicketPage
