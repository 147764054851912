// Dialogs.js
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';

const EditUsernameDialog = ({ open, setOpen, newUsername, setNewUsername, handleUsernameUpdate }) => (
    <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Username</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Enter the new username you want to set.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="New Username"
                type="text"
                fullWidth
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleUsernameUpdate}>Update</Button>
        </DialogActions>
    </Dialog>
);

const AddCoinsDialog = ({ open, setOpen, coinsToAdd, setCoinsToAdd, handleAddCoins }) => (
    <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Coins</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Enter the number of coins you want to add.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Coins"
                type="number"
                fullWidth
                value={coinsToAdd}
                onChange={(e) => setCoinsToAdd(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleAddCoins}>Add</Button>
        </DialogActions>
    </Dialog>
);

const ExtendTrialDialog = ({ open, setOpen, daysToExtend, setDaysToExtend, handleExtendTrial }) => (
    <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Extend Free Trial</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Enter the number of days to extend the free trial.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Days"
                type="number"
                fullWidth
                value={daysToExtend}
                onChange={(e) => setDaysToExtend(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleExtendTrial}>Extend</Button>
        </DialogActions>
    </Dialog>
);

export { EditUsernameDialog, AddCoinsDialog, ExtendTrialDialog };
