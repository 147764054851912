import secureLocalStorage from 'react-secure-storage'
import './TicketLogs.css'
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { getDayMonthFromMs } from '../../../Utils/time'
import { BASE_URL } from '../../../api/Constants'

function TicketLogs() {
    const [apiCalled, setApiCalled] = useState(false)
    const [labelArray, setLabelArray] = useState([])
    const [mailCountArray, setMailCountArray] = useState([])
    const [appCountArray, setAppCountArray] = useState([])
    const [totalCountArray, setTotalCountArray] = useState([])
    const [ticketsCountLog, setTicketsCountLog] = useState([])
    const [currentAgentId, setCurrentAgentId] = useState('all')
    const [startDate, setStartDate] = useState(1695651200000)
    const [endDate, setEndDate] = useState(new Date().getTime())
    const dashboardUid = secureLocalStorage.getItem('uid')

    const handleTicketStats = (ticketStatsList) => {
        const tempLabels = []
        const tempMailCounts = []
        const tempAppCounts = []
        const tempTotalCounts = []

        ticketStatsList.forEach((ticket) => {
            tempLabels.push(getDayMonthFromMs(ticket.time))
            tempMailCounts.push(ticket.mail_count)
            tempAppCounts.push(ticket.app_count)
            tempTotalCounts.push(ticket.mail_count + ticket.app_count)
        })

        setLabelArray(tempLabels)
        setMailCountArray(tempMailCounts)
        setAppCountArray(tempAppCounts)
        setTotalCountArray(tempTotalCounts)
    }

    const getFeedbackRatingStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        fetch(`${BASE_URL}/api/dashboard/v2/ticket-stats`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setApiCalled(true)
                    handleTicketStats(data.data.ticketsCount)
                } else {
                    console.error('Error: Data fetch failed')
                }
            })
            .catch((error) => {
                console.error('Error: ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats()
        }
    }, [apiCalled])

    return (
        <>
            <div>
                <p className='text-center text-4xl font-bold mt-12'>Tickets Per Day</p>
            </div>
            {labelArray.length > 0 && (
                <div className='ticketfeedback__container'>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: labelArray,
                                scaleType: 'band'
                            }
                        ]}
                        series={[
                            {
                                data: mailCountArray,
                                label: 'Mail Tickets',
                                stack: 'tickets',
                                color: 'blue'
                            },
                            {
                                data: appCountArray,
                                label: 'App Tickets',
                                stack: 'tickets',
                                color: 'green'
                            }
                        ]}
                        height={500}
                        barLabels={[
                            (_, idx) => `Total: ${totalCountArray[idx]}`
                        ]}
                        barLabelFormat={{
                            position: 'insideTop', // Ensure the label is visible inside the bar
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: 'black'
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default TicketLogs
