import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Switch } from '@mui/material';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import { formatDate, formatFieldName } from '../../../../Utils/string';

const TableSection = ({ userInfo, handleToggleSwitch, setEditUsernameOpen, setAddCoinsOpen, setExtendTrialOpen }) => {
    const hiddenKeys = ['authToken', '_id'];
    const date_keys = ['lastActive', 'createdAt', 'freeTrialExpiry', 'lastStreakReset'];

    const renderNestedFields = (object, parentKey) => {
        return Object.entries(object).map(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={`${parentKey}-${key}`}>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ fontWeight: 'bolder', backgroundColor: '#f4f4f4', padding: '10px' }}>
                                {formatFieldName(`${parentKey} - ${key}`)}
                            </TableCell>
                        </TableRow>
                        {renderNestedFields(value, `${parentKey}-${key}`)}
                    </React.Fragment>
                );
            } else {
                if (date_keys.includes(key)) {
                    value = formatDate(value);
                }
                return (
                    <TableRow key={`${parentKey}-${key}`} sx={{ borderBottom: '1px solid #ddd' }}>
                        <TableCell sx={{ fontWeight: '500', padding: '12px 16px', color: '#333' }}>
                            {formatFieldName(key)}
                        </TableCell>
                        <TableCell sx={{ padding: '12px 16px', color: '#555' }}>
                            {String(value)}
                        </TableCell>
                    </TableRow>
                );
            }
        });
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                width: '80%', 
                marginTop: '50px',
                marginBottom: '100px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '8px',
                boxShadow: 3,
                padding: '20px'
            }}
        >
            <Table sx={{ minWidth: 650 }}>
                <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bolder', fontSize: '16px', color: '#555' }}>#</TableCell>
                        <TableCell sx={{ fontWeight: 'bolder', fontSize: '16px', color: '#555' }}>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(userInfo).map(([key, value]) => {
                        if (hiddenKeys.includes(key)) return null;
                        if (typeof value === 'object' && value !== null) {
                            return (
                                <React.Fragment key={key}>
                                    <TableRow>
                                        <TableCell
                                            colSpan={2}
                                            sx={{
                                                fontWeight: 'bolder',
                                                backgroundColor: '#f4f4f4',
                                                padding: '10px',
                                                color: '#333',
                                            }}
                                        >
                                            {formatFieldName(key)}
                                        </TableCell>
                                    </TableRow>
                                    {renderNestedFields(value, key)}
                                </React.Fragment>
                            );
                        }
                        return (
                            <TableRow key={key} sx={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell sx={{ fontWeight: '500', padding: '12px 16px', color: '#333' }}>
                                    {formatFieldName(key)}
                                    {key === 'username' && (
                                        <IconButton
                                            size="small"
                                            onClick={() => setEditUsernameOpen(true)}
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#1976d2',
                                                '&:hover': { backgroundColor: '#f1f1f1' },
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                    {key === 'coins' && (
                                        <IconButton
                                            size="small"
                                            onClick={() => setAddCoinsOpen(true)}
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#1976d2',
                                                '&:hover': { backgroundColor: '#f1f1f1' },
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    )}
                                    {key === 'freeTrialExpiry' && (
                                        <IconButton
                                            size="small"
                                            onClick={() => setExtendTrialOpen(true)}
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#1976d2',
                                                '&:hover': { backgroundColor: '#f1f1f1' },
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell sx={{ padding: '12px 16px', color: '#555' }}>{String(value)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableSection;
